@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    // -webkit-touch-callout: none;
    // user-select: none;
    // overflow: hidden;
  }

  body {
    position: fixed;
    overflow: hidden;
    overscroll-behavior-y: none;
  }
}
